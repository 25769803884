import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Designs from "./Designs";
import Customizer from "./Customizer";


function Step() {
  const steps = [
    { num: 1, text: "Select Your Design", column: 4 },
    { num: 2, text: "Customize your hat", column: 4 },
  ];


  const [activeStep, setActiveStep] = useState(0);


  // const handleStepClick = (step) => {
  //   setActiveStep(step);
  // };

  const handleNextClick = (step, values) => {
    setActiveStep(step);
  };

  const handleBackClick = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <>
      <div className="step-outer-box">
        <Container id="raise-header">
          <Col xs={12} lg={8} className="m-auto pt-3 pb-2">
            <Row className="justify-content-center">
              {steps.map(({ num, text, column }, index) => {
                const isCurrent = activeStep === index;
                const isPrevious = activeStep > index;

            return (
              <Col
                xs={12}
                lg={column}
                className="py-2 d-flex step-outer"
                key={index}
           
              >
                <div className="d-flex">
                  <div
                    className="step-num"
                    style={{
                      background: isCurrent
                        ? "#377f93"
                        : isPrevious
                        ? "#38C793"
                        : "inherit",
                      color: isCurrent
                        ? "white"
                        : isPrevious
                        ? "white"
                        : "#666666",
                      border: "1px solid #E8E8E8",
                    }}
                  >
                    {isPrevious && "✓"}
                    {!isPrevious && num}
                  </div>
                  <div className=" step-text px-3 mt-1">{text}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Container>
  </div>

  <Container>
    <div className="bg-pattern" style={{ padding: "10px 0 " }}>
      {activeStep > 0 && activeStep !== 4 && (
        <Button onClick={handleBackClick} className="back-btn mt-3">
          
          Back
        </Button>
      )}

      {activeStep === 0 ? (
       <Designs
       
       handleNextClick= {handleNextClick}
       activeStep={activeStep}
       
       />
      ) : activeStep === 1 ? (
      <Customizer/>
      )  : (
        ""
      )}
    </div>
  </Container>
</>
  );
}

export default Step;
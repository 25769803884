import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Customizer from './components/Customizer';
import Main from './components/Main';
import Step from './components/Step';
import Editor from './components/Editor';
import Login from './components/Login';
import { AuthVerify } from './utils/AuthUtils';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const verify = AuthVerify(); // Check if user is authenticated

  return verify ? <Component {...rest} /> : <Navigate to="/login" />;
};

const HtRoutes = () => {
  return (
      <Routes>
      <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute component={Step} />}  />
        <Route path="/customizer"  element={<ProtectedRoute component={Customizer} />}/>
        <Route path="/editor" element={<ProtectedRoute component={Editor} />}/>
      </Routes>
  );
}

export default HtRoutes;

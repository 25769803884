import { React, useEffect, useRef, useState } from "react";
import { Stage, Layer, Image, Shape, Text } from "react-konva";
import useImage from "use-image";
import { HexColorPicker } from "react-colorful";
import { Button, Col, Image as Images, Row } from "react-bootstrap";
import logo from "../assets/barca.png";

import uploadIcon from "../assets/upload.svg";
import CanvasComponent from "../components/CanvasDeDibujo/CanvasComponent";
import { caps2 } from "../svg/caps2";
import cropIcon from "../assets/crop.svg";
import colorIcon from "../assets/color-fill.svg";
import saveIcon from "../assets/save.svg";
import jsPDF from "jspdf";
import { CompactPicker } from "react-color";
import Rectangle from "./Rectangle";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const FloodFillImage = ({ src, onFill }) => {
  const [image, status] = useImage(src); // useImage also provides a status you can check
  let imageRef = useRef(null);

  useEffect(() => {
    if (status === "loaded" && image) {
      // Image is loaded, you can now use it
      console.log("Image loaded:", image);
    }
  }, [status, image]);



  const handleMouseDown = (event) => {
    if (status !== "loaded" || !image) {
      console.warn("Image not ready for flood fill");
      return;
    }
    // Your existing logic...
  };

  return (
    <Image
      image={image}
      ref={imageRef}
      onMouseDown={handleMouseDown}
      width={300}
      height={300}
      x={200}
      y={200}
    />
  );
};

const Editor = ({uploadedImageSrc, onUpdate, index}) => {
  const [src, setSrc] = useState(logo);
  const imageRef = useRef(null);
  const [floodFillActive, setFloodFillActive] = useState(false);
  const [floodFillImage, setFloodFillImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const stageRef = useRef();
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [displaySvgColorPicker, setDisplaySvgColorPicker] = useState(false);
  const [isSvg, SetIsSvg] = useState(false);
  const [svgColors, setSvgColors] = useState([]);
  const [crop, setCrop] = useState();
  const [cropPreviewUrl, setCropPreviewUrl] = useState(null);
  const [cropActive, setCropActive] = useState(false);
  const [stageHeight, setStageHeight] = useState(700);
  const [stageWidth, setStageWidth] = useState(800);

  const onCropChange = (crop) => {
    setCrop(crop);
  };
  
  const onCropComplete = (crop) => {
    if (!floodFillImage || !crop.width || !crop.height) {
      console.error('Crop or source not set correctly');
      return;
    }
    generateCroppedImage(floodFillImage.src, crop).then((base64Image) => {
      setCropPreviewUrl(base64Image); 
      console.log(cropPreviewUrl)
      // This is now a base64 string
    });
  };
  // useEffect(() => {
  //   if (uploadedImageSrc) {
  //     const newImage = {
  //       id: `img${rectangles.length + 1}`,
  //       x: 100,
  //       y: 100,
  //       width: 100,
  //       height: 100,
  //       src: uploadedImageSrc
  //     };
  //     setRectangles([...rectangles, newImage]);
  //     console.log("uploaded rec:",rectangles)
  //   }
  // }, []);

  useEffect(() => {
    if (uploadedImageSrc) {
      setRectangles([]);
      const img = new window.Image();
      img.onload = () => {
        const maxWidth = 800;
        const maxHeight = 700;
        let width = img.width;
        let height = img.height;
    
        const aspectRatio = width / height;
    
        if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
        }
    
        if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
        }
    

        const x =10;
        const y = 10;
    
        const newImage = {
            id: `img${rectangles.length + 1}`,
            x, 
            y, 
            width, 
            height, 
            src: uploadedImageSrc.src
        };
        
        setRectangles([...rectangles, newImage]);
        selectShape(newImage.id);
        setStageHeight(height);
        setStageWidth(width);
        console.log("new:",newImage,"stage:",stageHeight,stageWidth)
    };
      img.src = uploadedImageSrc.src;
      
    }
  }, [uploadedImageSrc]);
  
  const saveChanges = () => {
    selectShape(null);
    const interval = setTimeout(() => {
      const stage = stageRef.current.getStage();
      const dataURL = stage.toDataURL(); 
      onUpdate(dataURL,uploadedImageSrc.index);
}, 200);
    
  };

  const generateCroppedImage = async (src, crop) => {
    return new Promise((resolve, reject) => {
      const image = new window.Image();
      image.src = src;
      image.onload = () => {
        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d')

        if (!ctx) {
          throw new Error('No 2d context')
        }
      
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const pixelRatio = window.devicePixelRatio
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio)
      
        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high'
      
        const cropX = crop.x * scaleX
        const cropY = crop.y * scaleY
        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2
      
        ctx.save()
      
        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY)
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY)
        // 2) Scale the image
        ctx.scale(1, 1)
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
        )
      
        ctx.restore()


  
        canvas.toBlob(blob => {
          if (!blob) {
            // Handle failure
            console.error('Canvas is empty');
            reject(new Error('Canvas is empty'));
            return;
          }
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result); // This is the base64 string
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }, 'image/png');
      };
      image.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  
  

  useEffect(() => {
    if (selectedId) {
      const selectedRect = rectangles.find((rect) => rect.id === selectedId);
      const isSelectedSvg = selectedRect && selectedRect.svg;
      SetIsSvg(isSelectedSvg);
      if (selectedRect && selectedRect.src) {
        const svgString = atob(selectedRect.src.split(",")[1]);
        const colors = svgString.match(/#[0-9a-f]{3,6}/gi) || [];
        setSvgColors(Array.from(new Set(colors)));
      }
    }
  }, [selectedId, rectangles]);

  const handleSvgColorChange = (newColor) => {
    if (!selectedId) return;

    setRectangles(
      rectangles.map((rect) => {
        if (rect.id === selectedId && rect.src) {
          let svgString = atob(rect.src.split(",")[1]);
          const oldColor = selectedColor;
          svgString = svgString.replaceAll(oldColor, newColor);
          const newSrc = `data:image/svg+xml;base64,${btoa(svgString)}`;
          return { ...rect, src: newSrc };
        }
        return rect;
      })
    );

    setSelectedColor(newColor);
  };
  const showSvgColorPicker = (toggle, oldColor) => {
    setDisplaySvgColorPicker(toggle);
    setSelectedColor(oldColor);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      const isSvg = file.type === "image/svg+xml";
      reader.onload = (e) => {
        const newImage = {
          id: `img${rectangles.length + 1}`,
          x: 100,
          y: 100,
          width: 100,
          height: 100,
          src: e.target.result,
          svg: isSvg,
        };
        setRectangles([...rectangles, newImage]);
        console.log("rectangles", newImage);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };
  const handleDelete = (id) => {
    setRectangles(rectangles.filter((rect) => rect.id !== id));
    selectShape(null);
  };

  //   const updateSvgColorByClass = (svgString, className, newColor) => {
  //     const parser = new DOMParser();
  //     const xmlDoc = parser.parseFromString(svgString, "image/svg+xml");
  //     const elements = xmlDoc.getElementsByClassName(className);
  //     setPartColors((prevColors) => ({
  //       ...prevColors,
  //       [className]: newColor,
  //     }));
  //     for (const element of elements) {
  //       const paths = element.getElementsByTagName("path");
  //       for (const path of paths) {
  //         path.style.fill = newColor;
  //       }
  //     }

  //     const serializer = new XMLSerializer();
  //     return serializer.serializeToString(xmlDoc);
  //   };
  const saveStageToPdf = () => {
    const stage = stageRef.current.getStage();

    const pdf = new jsPDF({
      orientation: "l",
      unit: "px",
      format: [stage.width(), stage.height()],
    });
    stage.toDataURL({
      pixelRatio: 3,
      callback(dataUrl) {
        pdf.addImage(dataUrl, "JPEG", 0, 0, stage.width(), stage.height());
        pdf.save("canvas.pdf");
      },
    });
  };
  const handleColorFill = (selectedId) => {
    const selectedImage = rectangles.find((rect) => rect.id === selectedId);
    if (selectedImage) {
      setFloodFillImage(selectedImage);
      setFloodFillActive(true);
    }
  };
  const handleCrop = (selectedId) => {
    const selectedImage = rectangles.find((rect) => rect.id === selectedId);
    if (selectedImage) {
      setFloodFillImage(selectedImage);
      setCropActive(true);
    }
  };
  const updateImage = (newImageDataUrl) => {
    setRectangles(
      rectangles.map((rect) => {
        if (rect.id === selectedId) {
          return { ...rect, src: newImageDataUrl };
        }
        return rect;
      })
    );
    setFloodFillActive(false); 
  };

  const handleSaveCroppedImage = () => {
    if (!cropPreviewUrl) {
      console.error('No cropped image to save.');
      return;
    }
  
    // Assuming updateImage expects a base64 string
    updateImage(cropPreviewUrl); 
    setFloodFillImage("");
    setCropActive(false); 
    setCrop("");
  };
  
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="container"
    >
      <Row className="justify-content-between mt-5">
    {!floodFillActive && (<Col xs={12} lg={3} className="mt-3 text-center">
          <div>
            {selectedId && (
              <div className="">
  <Button
                onClick={() => handleColorFill(selectedId)}
                className=" edit-btn w-100"
                // Only show this button if an image is selected
              >
                <div className="d-flex align-items-center justify-content-center">
                  <div className="">
                  <img src={colorIcon} alt="" />
                  </div>
                  <span className="ms-2"> Edit Colors </span>
                 
                </div>
              </Button>
              
              <Button className="mt-3  edit-btn w-100" onClick={() => handleCrop(selectedId)} >
                <div className="d-flex align-items-center justify-content-center">
                  <div className="">
                    <img src={cropIcon} alt="" />
                  </div>
                  <span className="ms-2">Crop Image</span> 
                </div>
              </Button>

              <Button   onClick={() => {saveChanges()}} className="mt-3  edit-btn w-100">
              <div className="d-flex align-items-center justify-content-center">
                  <div className="">
                    <img src={saveIcon} alt="" />
                  </div>
                  <span className="ms-2">Save Changes</span> 
                </div>
                    </Button>
              </div>
            

            )}
            {selectedId && isSvg && (
              <div>
                <h3 className="">Selected Item</h3>

                {svgColors.map((color, index) => (
                  <div
                    key={index}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: color,
                      display: "inline-block",
                      marginRight: "10px",
                      border: "1px dashed black",
                    }}
                    onClick={() => showSvgColorPicker(true, color)}
                  ></div>
                ))}
                {displaySvgColorPicker && (
                  <div className="w-75">
                    <HexColorPicker
                      color={selectedColor}
                      onChange={handleSvgColorChange}
                    />
                    <div className=" my-2 ">
                      <Button
                        className="mx-auto bg-dark text-white border-0 shadow-lg w-100"
                        onClick={() => showSvgColorPicker(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>)}

        {floodFillActive && (
          <Col xs={12} lg={12}>
            <div className="">
              <CanvasComponent
                image={{ floodFillImage, onUpdate: updateImage }}
                onFloodFillComplete={() => setFloodFillActive(false)} // Optionally, provide a callback to deactivate flood fill mode after completion
              />
            </div>
          </Col>
        )}

{cropActive && (
  <Col xs={12} lg={9}>
    <Button onClick={handleSaveCroppedImage}>Save Changes</Button>
    <ReactCrop
     // You may need to adjust this to point to the correct image source
    crop={crop}
    onChange={onCropChange}
    onComplete={onCropComplete}
  >
    <img src={floodFillImage.src} ref={imageRef} className="w-100" alt="" />
  </ReactCrop>
  
  </Col>
  
  
)}

        {!floodFillActive && !cropActive && (
          <Col xs={12} lg={9} className="">
            <div
              style={{ width: `${stageWidth+20}px`, height:`${stageHeight+20}px` }}
              className="bg-white  my-2 m-auto"
            >
              <div className="m-1">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="fileUploader"
                  onChange={handleFileSelect}
                />
                <div className="d-flex justify-content-between bg-light p-2">
                  <div>
                    {/* <Button
                      onClick={() =>
                        document.getElementById("fileUploader").click()
                      }
                      className=""
                    >
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          <Images src={uploadIcon} />
                        </div>
                        <div className="">Upload</div>
                      </div>
                    </Button> */}
                  </div>
                  <div className="">
                    {/* <Button onClick={saveStageToPdf} className="py-2">
                      Save to PDF
                    </Button> */}
                  
                  </div>
                </div>
              </div>

              <Stage
                width={stageWidth+20}
                height={stageHeight+20}
                className="stage"
                scale={{ x: 1, y: 1 }}
                onMouseDown={(e) => {
                  // deselect when clicked on empty area
                  const clickedOnEmpty = e.target === e.target.getStage();
                  if (clickedOnEmpty) {
                    selectShape(null);
                  }
                }}
                ref={stageRef}
              >
                <Layer>
                  {/* <FloodFillImage src={src} onFill={handleFill} /> */}
                </Layer>
                <Layer>
                  {rectangles.map((rect, i) => {
                    return (
                      <Rectangle
                        key={i}
                        shapeProps={rect}
                        isSelected={rect.id === selectedId}
                        onSelect={() => {
                          selectShape(rect.id);
                        }}
                        onChange={(newAttrs) => {
                          const rects = rectangles.slice();
                          rects[i] = newAttrs;
                          setRectangles(rects);
                        }}
                        onDelete={() => handleDelete(rect.id)}
                      />
                    );
                  })}
                </Layer>
              </Stage>
            </div>
          </Col>
        )}
      </Row>
    </div>
    // <>
    // <CanvasComponent/>
    // </>
  );
};

export default Editor;

import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';

const Header = () => {
const navigate= useNavigate();
  const logout= () =>{
    localStorage.removeItem("verify");
    navigate("/login");
  }

  return (
    <Navbar expand="lg" className="top-bar py-3">
    <Container>
      <Navbar.Brand href="/">HT-Publishing</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Button onClick={logout}> Log Out</Button>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default Header
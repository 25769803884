import React, { useState } from 'react';
import { Card, Col, Container } from 'react-bootstrap';
import cap from "../assets/standard.png";

const Designs = ({activeStep,handleNextClick}) => {
  // State to track if the card is selected
  const [isSelected, setIsSelected] = useState(false);


  // Function to toggle the selected state
  const toggleSelect = () => {
    setIsSelected(!isSelected);
    handleNextClick(activeStep+1)
  };

  return (
    <Container>
      <Col xs={12} lg={4} className='mx-auto'>
      <div className="mx-auto text-center d-flex justify-content-center align-items-center mt-5">
        <Card 
          style={{ 
         
            border: isSelected ? "2px solid blue" : "2px solid #6bc9e2",
            cursor: 'pointer' ,
            borderRadius:"20px",
            overflow:"hidden",
            boxShadow: "rgba(107, 201, 226, 0.2) 0px 18px 50px -10px",
          }} 
     
          onClick={toggleSelect} // Toggle select state on click
        >
          <Card.Img variant="top" src={cap} />
          <Card.Body>
            <Card.Title style={{color:"#6bc9e2",fontWeight:"600"}}>STANDARD DESIGN</Card.Title>
          </Card.Body>
        </Card>
      </div>
      <div>

    </div>
    </Col>
    </Container>
  );
};

export default Designs;

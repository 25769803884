import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Image, Rect, Transformer } from 'react-konva';
import deleteIcon from "../assets/delete.svg";
import useImage from 'use-image';

const Rectangle = ({
    shapeProps,
    isSelected,
    onSelect,
    onChange,
    onDelete,
  }) => {
    const [image] = useImage(shapeProps.src);
    const shapeRef = useRef();
    const trRef = useRef();
    const [deleteImage] = useImage(deleteIcon);
    const [isTransforming, setIsTransforming] = useState(false);
    useEffect(() => {
      if (isSelected) {
        // shapeRef.current.cache();
        // we need to attach transformer manually
        trRef.current.setNode(shapeRef.current);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
  
    useLayoutEffect(() => {
      shapeRef.current.cache();
    }, [shapeProps, image, isSelected]);
    return (
      <Fragment>
        <Image
          image={image}
          onClick={onSelect}
          onTransformStart={(e) => {
            setIsTransforming(true);
          }}
          ref={shapeRef}
          {...shapeProps}
          draggable
          onDragStart={(e) => {
            setIsTransforming(true);
          }}
          onDragEnd={(e) => {
            onChange({
              ...shapeProps,
              x: e.target.x(),
              y: e.target.y(),
            });
            setIsTransforming(false);
          }}
          onTransformEnd={(e) => {
            setIsTransforming(false);
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);
            node.width(Math.max(5, node.width() * scaleX));
            node.height(Math.max(node.height() * scaleY));
  
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: node.width(),
              height: node.height(),
            });
          }}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
            onTransformStart={(e) => {
              setIsTransforming(true);
            }}
            onTransformEnd={(e) => {
              setIsTransforming(false);
            }}
          />
        )}
        {isSelected && !isTransforming && (
          <Rect
            width={25}
            height={25}
            fillPatternImage={deleteImage}
            x={shapeProps.x - 0}
            y={shapeProps.y - 30}
            onClick={onDelete}
            onTap={onDelete}
          />
        )}
      </Fragment>
    );
  };

export default Rectangle
import { React, useEffect, useRef, useState } from "react";
import { Stage, Layer, Image, Shape } from "react-konva";
import useImage from "use-image";
import { HexColorPicker } from "react-colorful";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { Image as Images } from "react-bootstrap";
import uploadIcon from "../assets/upload.svg";
import editIcon from "../assets/edit.svg";
//import Caps, { ReactComponent as Cap } from "../assets/cap.svg";
//import URLImage from "./shared/URLImage";
//import Konva from "konva";
import { caps2 } from "../svg/caps2";
import jsPDF from "jspdf";
import { CompactPicker, GithubPicker, TwitterPicker } from "react-color";
import Rectangle from "./Rectangle";
import Editor from "./Editor";

// const UploadedImage = ({ src, x, y, rotation, scaleX, scaleY }) => {
//   const [image] = useImage(src);

//   return (
//     <Image
//       image={image}
//       x={x}
//       y={y}
//       rotation={rotation}
//       scaleX={scaleX}
//       scaleY={scaleY}
//     />
//   );
// };

// const SkewedImage = ({ src, x, y, skewX, skewY, type }) => {
//   const [image] = useImage(src);

//   const drawScene = (context, shape) => {
//     if (image) {
//       if (type === 1) {
//         context.save();
//         context.transform(1, 0.2, -0.1, 1, 0, 0);
//         context.drawImage(image, 301, 100, 80, 90);
//         context.restore();
//       } if(type ===2){
//         context.save();
//         context.translate(239, 837);
//         context.rotate((2.7 * Math.PI) / 180);
//         context.beginPath();
//         context.rect(0, -90, 17, 90);
//         context.clip();
//         context.transform(1, 0.1, 0, 1, 0, 0);
//         context.drawImage(image, -17.5, -90, 35, 45);
//         context.restore();
//       }
//       if (type===3){
//         context.save();
//         context.translate(716, 837);
//         context.rotate((-2.7 * Math.PI) / 180);
//         context.beginPath();
//         context.rect(0, -90, 17, 90);
//         context.clip();
//         context.transform(1, -0.1, 0, 1, 0, 0);
//         context.drawImage(image, 0, -90, 35, 45);
//         context.restore();
//       }
//       else{
//         context.save();
//         context.translate(270, 518);
//         context.rotate((90 * Math.PI) / 180);
//         context.transform(1, 0, 0, 0.8, 0, 0); // apply skew transformation
//         context.drawImage(image, 0, 0, 40, 45);
//         context.restore();
//       }
//     }
//   };

//   return (
//     <Shape
//       sceneFunc={(context, shape) => {
//         drawScene(context, shape);
//         context.fillStrokeShape(shape);
//       }}
//     />
//   );
// };

const SkewedImage = ({
  src,
  position,
  translate,
  rotation,
  skew,
  clip,
  size,
}) => {
  const [image] = useImage(src);

  const drawScene = (context, shape) => {
    if (image) {
      context.save();

      // Translate to the initial position
      if (translate) {
        context.translate(translate.x, translate.y);
      }

      const centerX = position.x + size.width / 2;
      const centerY = position.y + size.height / 2;

      // Move to the center of the image to apply transformations around this point
      context.translate(centerX, centerY);

      // Apply rotation
      if (rotation) {
        context.rotate((rotation.angle * Math.PI) / 180);
      }

      // Apply scale
      if (size.scale) {
        context.scale(size.scale, size.scale);
      }

      // Move back from the center to draw the image
      context.translate(-centerX, -centerY);

      // Apply clipping if necessary
      if (clip) {
        context.beginPath();
        context.rect(clip.x, clip.y, clip.width, clip.height);
        context.clip();
      }

      // Apply skew transformation
      if (skew) {
        context.transform(1, skew.y, skew.x, 1, 0, 0); // Assuming skew.z is not used for simplicity
      }

      // Draw the image with the specified size, adjusted for scaling
      // The drawing position needs to be adjusted to compensate for the earlier translation
      context.drawImage(
        image,
        -size.width / 2,
        -size.height / 2,
        size.width,
        size.height
      );

      context.restore();
    }
  };

  return (
    <Shape
      sceneFunc={(context, shape) => {
        drawScene(context, shape);
        context.fillStrokeShape(shape); // Required for Konva to perform fill and stroke
      }}
    />
  );
};

const Customizer = () => {
  const [uploadedImageSrc, setUploadedImageSrc] = useState(null);
  const [BackLogoSrc, setBackLogoSrc] = useState(null);
  const [SideLogoSrc, setSideLogoSrc] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (image, index) => {
    setEditImage({
      src: image,
      index: index,
    });
    setShow(true);
  };

  const fileInputRef = useRef();
  const backLogoRef = useRef();
  const sideLogoRef = useRef();

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handlePersonalClick = () => {
    backLogoRef.current.click();
  };
  const handleSideClick = () => {
    sideLogoRef.current.click();
  };
  const customColors = [
    { color: "#FEC953", name: "Athletic Gold" },
    { color: "#313233", name: "Black" },
    { color: "#A52E44", name: "Cardinal" },
    { color: "#F5F4EA", name: "College White" },
    { color: "#535A61", name: "Dark Graphite" },
    { color: "#0A5A50", name: "Dark Green" },
    { color: "#1E3F6F", name: "Dark Royal" },
    { color: "#AEABAA", name: "Purple" },
    { color: "#9F4131", name: "Purple" },
    { color: "#0F7C53", name: "Purple" },
    { color: "#163A61", name: "Purple" },
    { color: "#0E66A6", name: "Purple" },
    { color: "#4E314A", name: "Purple" },
    { color: "#133A55", name: "Purple" },
    { color: "#FEFEFE", name: "Purple" },
    { color: "#E66830", name: "Purple" },
    { color: "#543E6E", name: "Purple" },
    { color: "#F69E45", name: "Purple" },
    { color: "#B24E36", name: "Purple" },
    { color: "#EA3458", name: "Purple" },
    { color: "#59A4D1", name: "Purple" },
    { color: "#D4C9A4", name: "Purple" },
    { color: "#564436", name: "Purple" },

    // Add more colors as needed
  ];

  const imageProps = [
    {
      //         context.transform(1, 0.2, -0.1, 1, 0, 0);
      //         context.drawImage(image, 301, 100, 80, 90);
      src: uploadedImageSrc || "",
      position: { x: 128, y: 51 },
      translate: { x: 326, y: 220 }, // Position on canvas
      skew: { x: -0.1, y: 0.2 },
      size: { width: 80, height: 90 }, // Size on canvas
    },
    {
      //         context.translate(239, 837);
      //         context.rotate((2.7 * Math.PI) / 180);
      //         context.beginPath();
      //         context.rect(0, -90, 17, 90);
      //         context.clip();
      //         context.transform(1, 0.1, 0, 1, 0, 0);
      //         context.drawImage(image, -17.5, -90, 35, 45);
      //         context.restore();
      src: uploadedImageSrc || "",
      position: { x: -17.5, y: -90 },
      translate: { x: 245, y: 775 }, // Position on canvas
      rotation: { angle: 2.5 }, // Skew values
      clip: { x: 0, y: -90, width: 50, height: 120 }, // Clipping area
      size: { width: 34, height: 38 }, // Size on canvas
    },
    {
      //         context.save();
      //         context.translate(716, 837);
      //         context.rotate((-2.7 * Math.PI) / 180);
      //         context.beginPath();
      //         context.rect(0, -90, 17, 90);
      //         context.clip();
      //         context.transform(1, -0.1, 0, 1, 0, 0);
      //         context.drawImage(image, 0, -90, 35, 45);
      //         context.restore();

      src: uploadedImageSrc || "",
      position: { x: 0, y: -90 },
      translate: { x: 727.5, y: 775 }, // Position on canvas
      rotation: { angle: -2.3 }, // Skew values
      clip: { x: -49, y: -90, width: 50, height: 120 }, // Clipping area
      size: { width: 34, height: 38 }, // Size on canvas
    },
    {
      //         context.save();
      //         context.translate(270, 518);
      //         context.rotate((90 * Math.PI) / 180);
      //         context.transform(1, 0, 0, 0.8, 0, 0); // apply skew transformation
      //         context.drawImage(image, 0, 0, 40, 45);
      //         context.restore();

      src: uploadedImageSrc || "",
      position: { x: 0, y: 0 },
      translate: { x: 208, y: 544.5 }, // Position on canvas
      rotation: { angle: 90 }, // Rotation angle in degrees
      skew: { x: 0, y: 0, z: 0.5 }, // Skew values
      size: { width: 38, height: 25 }, // Size on canvas
    },
  ];

  const backLogo = [
    {
      src: BackLogoSrc || "",
      position: { x: 0, y: 0 },
      translate: { x: 650, y: 592 },
      size: { width: 40, height: 25 },
    },
    {
      src: BackLogoSrc || "",
      position: { x: 0, y: 0 },
      translate: { x: 517, y: 797 }, // Position on canvas
      rotation: { angle: 1 }, // Skew values
      clip: { x: 0, y: -90, width: 50, height: 120 }, // Clipping area
      size: { width: 35, height: 25 }, // Size on canvas
    },
    {
      src: BackLogoSrc || "",
      position: { x: 0, y: 0 },
      translate: { x: 456, y: 797 }, // Position on canvas
      rotation: { angle: -2.2 }, // Skew values
      clip: { x: -49, y: -90, width: 50, height: 120 }, // Clipping area
      size: { width: 35, height: 25 }, // Size on canvas
    },
  ];

  const sideLogoProps = [
    {
      src: SideLogoSrc || "",
      position: { x: 0, y: 0 },
      translate: { x: 635, y: 790 }, // Position on canvas
      rotation: { angle: -2.5 }, // Rotation angle in degrees
      size: { width: 55, height: 55 }, // Size on canvas
    },
  ];

  const [FrontProps, setFrontProps] = useState(imageProps);
  const [BackProps, setBackProps] = useState(backLogo);
  const [SideProps, setSideProps] = useState(sideLogoProps);

  const adjustSize = (widthChange, heightChange, index) => {
    if (index === 1) {
      setFrontProps((prevProps) => {
        return prevProps.map((item, index) => {
          return {
            ...item,
            size: {
              width:
                index !== 0
                  ? item.size.width + widthChange / 2
                  : item.size.width + widthChange,
              height:
                index !== 0
                  ? item.size.height + heightChange / 2
                  : item.size.height + heightChange,
            },
            translate: {
              x:
                index === 3
                  ? item.translate.x - widthChange / 4
                  : item.translate.x,
              y: item.translate.y,
            },
          };
        });
      });
    } else if (index === 2) {
      setBackProps((prevProps) => {
        return prevProps.map((item) => {
          return {
            ...item,
            size: {
              width: item.size.width + widthChange,
              height: item.size.height + heightChange,
            },
          };
        });
      });
    } else if (index === 3) {
      setSideProps((prevProps) => {
        return prevProps.map((item) => {
          return {
            ...item,
            size: {
              width: item.size.width + widthChange,
              height: item.size.height + heightChange,
            },
          };
        });
      });
    }
  };

  const resetSize = (index) => {
    if (index === 1) {
      setFrontProps(imageProps);
    } else if (index === 2) {
      setBackProps(backLogo);
    } else if (index === 3) {
      setSideProps(sideLogoProps);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImageSrc(reader.result);
        setFrontProps((prevProps) => {
          return prevProps.map((item) => {
            return {
              ...item,
              src: reader.result,
            };
          });
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBackLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackLogoSrc(reader.result);
        setBackProps((prevProps) => {
          return prevProps.map((item) => {
            return {
              ...item,
              src: reader.result,
            };
          });
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSideLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSideLogoSrc(reader.result);
        setSideProps((prevProps) => {
          return prevProps.map((item) => {
            return {
              ...item,
              src: reader.result,
            };
          });
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setUploadedImageSrc(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const [rectangles, setRectangles] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [partColors, setPartColors] = useState({
    crown: "#FFFFFF",
    visor: "#FFFFFF",
    undervisor: "#FFFFFF",
    eyelets: "#FFFFFF",
    button: "#FFFFFF",
    "front-left": "#FFFFFF",
    "front-right": "#FFFFFF",
    "mid-left": "#FFFFFF",
    "mid-right": "#FFFFFF",
    "rear-left": "#FFFFFF",
    "rear-right": "#FFFFFF",
    "newera-flag": "#808285",
  });
  const stageRef = useRef();
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const SVG = caps2;
  const [url, setNewUrl] = useState(SVG);
  const [image] = useImage("data:image/svg+xml;base64," + window.btoa(url));
  const [displaySvgColorPicker, setDisplaySvgColorPicker] = useState(false);
  const [isSvg, SetIsSvg] = useState(false);
  const [svgColors, setSvgColors] = useState([]);

  useEffect(() => {
    if (selectedId) {
      const selectedRect = rectangles.find((rect) => rect.id === selectedId);
      const isSelectedSvg = selectedRect && selectedRect.svg;
      SetIsSvg(isSelectedSvg);
      if (selectedRect && selectedRect.src) {
        const svgString = atob(selectedRect.src.split(",")[1]);
        const colors = svgString.match(/#[0-9a-f]{3,6}/gi) || [];
        setSvgColors(Array.from(new Set(colors)));
      }
    }
  }, [selectedId, rectangles]);

  const handleSvgColorChange = (newColor) => {
    if (!selectedId) return;

    setRectangles(
      rectangles.map((rect) => {
        if (rect.id === selectedId && rect.src) {
          let svgString = atob(rect.src.split(",")[1]);
          const oldColor = selectedColor;
          svgString = svgString.replaceAll(oldColor, newColor);
          const newSrc = `data:image/svg+xml;base64,${btoa(svgString)}`;
          return { ...rect, src: newSrc };
        }
        return rect;
      })
    );

    setSelectedColor(newColor);
  };
  const showSvgColorPicker = (toggle, oldColor) => {
    setDisplaySvgColorPicker(toggle);
    setSelectedColor(oldColor);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      const isSvg = file.type === "image/svg+xml";
      reader.onload = (e) => {
        const newImage = {
          id: `img${rectangles.length + 1}`,
          x: 100,
          y: 100,
          width: 100,
          height: 100,
          src: e.target.result,
          svg: isSvg,
        };
        setRectangles([...rectangles, newImage]);
        console.log("rectangles", newImage);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };
  const handleDelete = (id) => {
    setRectangles(rectangles.filter((rect) => rect.id !== id));
    selectShape(null);
  };

  const updateSvgColorByClass = (svgString, className, newColor) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgString, "image/svg+xml");
    const elements = xmlDoc.getElementsByClassName(className);
    setPartColors((prevColors) => ({
      ...prevColors,
      [className]: newColor,
    }));
    for (const element of elements) {
      const paths = element.getElementsByTagName("path");
      for (const path of paths) {
        path.style.fill = newColor;
      }
    }

    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc);
  };
  const handleColorsChange = (className, newColor) => {
    const updatedSvgString = updateSvgColorByClass(url, className, newColor);
    setNewUrl(updatedSvgString);
  };
  const saveStageToPdf = () => {
    const stage = stageRef.current.getStage();

    const pdf = new jsPDF({
      orientation: "l",
      unit: "px",
      format: [stage.width(), stage.height()],
    });
    stage.toDataURL({
      pixelRatio: 3,
      callback(dataUrl) {
        pdf.addImage(dataUrl, "JPEG", 0, 0, stage.width(), stage.height());
        pdf.save("canvas.pdf");
      },
    });
  };
  const updateImageSrc = (newSrc, index) => {
    if (index === 1) {
      setUploadedImageSrc(newSrc);
    } else if (index === 2) {
      setBackLogoSrc(newSrc);
    } else {
      setSideLogoSrc(newSrc);
    }
    handleClose();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="container"
    >
      <Row className="justify-content-between mt-5">
        <Col xs={12} lg={3}>
          <div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Front Logo</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={6}>
                      <div className="my-2">
                        <input
                          ref={fileInputRef}
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />

                        <Button
                          className=" back-btn"
                          onClick={handleUploadClick}
                        >
                          {uploadedImageSrc ? "Replace" : "Upload"}
                        </Button>
                      </div>
                    </Col>

                    <Col xs={6}>
                      {uploadedImageSrc && (
                        <>
                          <Row>
                            <Col xs={6}>
                              <div className="mt-2">
                                <Button
                                  className="  icon-btn"
                                  onClick={() =>
                                    handleShow(uploadedImageSrc, 1)
                                  }
                                >
                                  <img src={editIcon} className="" alt="" />
                                </Button>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="border-2 p-1 card d-flex align-items-center mt-2">
                                <img
                                  src={uploadedImageSrc}
                                  style={{ width: "80%" }}
                                  alt=""
                                />
                              </div>
                            </Col>
                            <Col></Col>
                          </Row>

                          {editImage && editImage.index === 1 && (
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-xl"
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Edit Image</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Editor
                                  uploadedImageSrc={editImage}
                                  onUpdate={updateImageSrc}
                                  index={1}
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      )}
                    </Col>

                    

                    {uploadedImageSrc && (
                      <>
                      
                        <Row className="">
                          <h5>Adjust Size</h5>
                          <Col
                            xs={6}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <Button
                                className="back-btn"
                                onClick={() => adjustSize(10, 10, 1)}
                              >
                                Increase (+)
                              </Button>
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <Button
                                className="back-btn"
                                onClick={() => adjustSize(-10, -10, 1)}
                              >
                                Decrease (-)
                              </Button>
                            </div>
                          </Col>
                          <Col xs={12}className="text-center">
                          <div>
                              <Button
                                className="back-btn mx-auto mt-3 w-100"
                                onClick={() => resetSize(1)}
                              >
                                Reset Size
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        
                      </>
                    )}
                  </Row>
                  
                
                  <div className="d-flex align-items-center"></div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Back Logo</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={6}>
                      <div className="my-2">
                        <input
                          ref={backLogoRef}
                          type="file"
                          onChange={handleBackLogoChange}
                          style={{ display: "none" }}
                        />

                        <Button
                          className="back-btn"
                          onClick={handlePersonalClick}
                        >
                          {BackLogoSrc ? "Replace" : "Upload"}
                        </Button>
                      </div>
                    </Col>
                    
                    <Col xs={6}>
                      {BackLogoSrc && (
                        <>
                          <Row>
                            <Col xs={6}>
                              <div className="mt-2">
                                <Button
                                  className="icon-btn "
                                  onClick={() => handleShow(BackLogoSrc, 2)}
                                >
                                  <img src={editIcon} className="" alt="" />
                                </Button>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="border-2 p-1 card d-flex align-items-center mt-2">
                                <img
                                  src={BackLogoSrc}
                                  className=""
                                  width={"80%"}
                                  alt=""
                                />
                              </div>
                            </Col>
                            
                          </Row>

                          {editImage && editImage.index === 2 && (
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-xl"
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Edit Image</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Editor
                                  uploadedImageSrc={editImage}
                                  onUpdate={updateImageSrc}
                                  index={2}
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      )}
                    </Col>
                    {BackLogoSrc && (
                      <>
                        <Row className="">
                          <h5>Adjust Size</h5>
                          <Col
                            xs={6}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <Button
                                className="back-btn"
                                onClick={() => adjustSize(10, 10, 2)}
                              >
                                Increase (+)
                              </Button>
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <Button
                                className="back-btn"
                                onClick={() => adjustSize(-10, -10, 2)}
                              >
                                Decrease (-)
                              </Button>
                            </div>
                          </Col>
                          <Col xs={12}className="text-center">
                          <div>
                              <Button
                                className="back-btn mx-auto mt-3 w-100"
                                onClick={() => resetSize(2)}
                              >
                                Reset Size
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                  <div className="d-flex align-items-center"></div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Side Logo</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={6}>
                      <div className="my-2">
                        <input
                          ref={sideLogoRef}
                          type="file"
                          onChange={handleSideLogoChange}
                          style={{ display: "none" }}
                        />

                        <Button className="back-btn" onClick={handleSideClick}>
                          {SideLogoSrc ? "Replace" : "Upload"}
                        </Button>
                      </div>
                    </Col>
                    <Col xs={6}>
                      {SideLogoSrc && (
                        <>
                          <Row>
                            <Col xs={6}>
                              <div className="">
                                <Button
                                  className="mt-2 icon-btn"
                                  onClick={() => handleShow(SideLogoSrc, 3)}
                                >
                                  <img src={editIcon} className="w-75" alt="" />
                                </Button>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="border-2 p-1 mt-2 card d-flex align-items-center">
                                <img
                                  src={SideLogoSrc}
                                  className=""
                                  width={"80%"}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </Row>

                          {editImage && editImage.index === 3 && (
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="modal-xl"
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Edit Image</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Editor
                                  uploadedImageSrc={editImage}
                                  onUpdate={updateImageSrc}
                                  index={3}
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </>
                      )}
                    </Col>
                    {SideLogoSrc && (
                      <>
                        <Row className="">
                          <h5>Adjust Size</h5>
                          <Col
                            xs={6}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <Button
                                className="back-btn"
                                onClick={() => adjustSize(10, 10, 3)}
                              >
                                Increase (+)
                              </Button>
                            </div>
                          </Col>
                          <Col
                            xs={6}
                            className="d-flex justify-content-between"
                          >
                            <div>
                              <Button
                                className="back-btn"
                                onClick={() => adjustSize(-10, -10, 3)}
                              >
                                Decrease (-)
                              </Button>
                            </div>
                          </Col>
                          <Col xs={12}className="text-center">
                          <div>
                              <Button
                                className="back-btn mx-auto mt-3 w-100"
                                onClick={() => resetSize(3)}
                              >
                                Reset Size
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                  <div className="d-flex align-items-center"></div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3 className="product-heading pt-3 pb-2">Product Colors</h3>
            <div className=" my-2">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>CROWN</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["crown"]}
                      onChange={(color) =>
                        handleColorsChange("crown", color.hex)
                      }
                      colors={customColors.map((c) => c.color)}
                      className="w-100 text-center"
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["crown"]}
                      onChange={(color) => handleColorsChange("crown", color)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>VISOR</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["visor"]}
                      onChange={(color) =>
                        handleColorsChange("visor", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["visor"]}
                      onChange={(color) => handleColorsChange("visor", color)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>UNDERVISOR</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["undervisor"]}
                      onChange={(color) =>
                        handleColorsChange("undervisor", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["undervisor"]}
                      onChange={(color) =>
                        handleColorsChange("undervisor", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>EYELETS</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["eyelets"]}
                      onChange={(color) =>
                        handleColorsChange("eyelets", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["eyelets"]}
                      onChange={(color) => handleColorsChange("eyelets", color)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>BUTTON</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["button"]}
                      onChange={(color) =>
                        handleColorsChange("button", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["button"]}
                      onChange={(color) => handleColorsChange("button", color)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>NEWERA FLAG</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["newera-flag"]}
                      onChange={(color) =>
                        handleColorsChange("newera-flag", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["newera-flag"]}
                      onChange={(color) =>
                        handleColorsChange("newera-flag", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <h3 className="product-heading pt-3 pb-2">
              Customize Individual Panels
            </h3>
            <div className="">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>FRONT LEFT</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["front-left"]}
                      onChange={(color) =>
                        handleColorsChange("front-left", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["front-left"]}
                      onChange={(color) =>
                        handleColorsChange("front-left", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>FRONT RIGHT</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["front-right"]}
                      onChange={(color) =>
                        handleColorsChange("front-right", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["front-right"]}
                      onChange={(color) =>
                        handleColorsChange("front-right", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>MID LEFT</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["mid-left"]}
                      onChange={(color) =>
                        handleColorsChange("mid-left", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["mid-left"]}
                      onChange={(color) =>
                        handleColorsChange("mid-left", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>MID RIGHT</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["mid-right"]}
                      onChange={(color) =>
                        handleColorsChange("mid-right", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["mid-right"]}
                      onChange={(color) =>
                        handleColorsChange("mid-right", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>REAR LEFT</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["rear-left"]}
                      onChange={(color) =>
                        handleColorsChange("rear-left", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["rear-left"]}
                      onChange={(color) =>
                        handleColorsChange("rear-left", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>REAR RIGHT</Accordion.Header>
                  <Accordion.Body>
                    <TwitterPicker
                      color={partColors["rear-right"]}
                      onChange={(color) =>
                        handleColorsChange("rear-right", color.hex)
                      }
                      className="w-100"
                      colors={customColors.map((c) => c.color)}
                    />
                    <HexColorPicker
                      className="w-100 mt-1"
                      color={partColors["rear-right"]}
                      onChange={(color) =>
                        handleColorsChange("rear-right", color)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div>
            {selectedId && isSvg && (
              <div>
                <h3 className="">Selected Item</h3>

                {svgColors.map((color, index) => (
                  <div
                    key={index}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: color,
                      display: "inline-block",
                      marginRight: "10px",
                      border: "1px dashed black",
                    }}
                    onClick={() => showSvgColorPicker(true, color)}
                  ></div>
                ))}
                {displaySvgColorPicker && (
                  <div className="w-75">
                    <HexColorPicker
                      color={selectedColor}
                      onChange={handleSvgColorChange}
                    />
                    <div className=" my-2 ">
                      <Button
                        className="mx-auto bg-dark text-white border-0 shadow-lg w-100"
                        onClick={() => showSvgColorPicker(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} lg={9} className="">
          <div
            style={{ width: "100%" }}
            className="bg-white border border-2 my-2"
          >
            <div className="m-1">
              {/* <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="fileUploader"
                onChange={handleFileSelect}
              /> */}
              <div className="d-flex justify-content-between">
                {/* <div>
                  <Button
                    onClick={() =>
                      document.getElementById("fileUploader").click()
                    }
                    className=""
                  >
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <Images src={uploadIcon} />
                      </div>
                      <div className="">Upload</div>
                    </div>
                  </Button>
                </div> */}
                <div className="">
                  <Button
                    onClick={saveStageToPdf}
                    className="py-2 ms-2 mt-2 back-btn"
                  >
                    Save to PDF
                  </Button>
                </div>
              </div>
            </div>
            <Stage
              width={850}
              height={900}
              scale={{ x: 1, y: 1 }}
              onMouseDown={(e) => {
                // deselect when clicked on empty area
                const clickedOnEmpty = e.target === e.target.getStage();
                if (clickedOnEmpty) {
                  selectShape(null);
                }
              }}
              ref={stageRef}
            >
              <Layer>
                <Image image={image} width={710} height={840} x={120} y={20} />
              </Layer>
              <Layer>
                {uploadedImageSrc && (
                  <>
                    {FrontProps.map((image, index) => {
                      console.log(image); // Debugging each image's props
                      return <SkewedImage key={index} {...image} />;
                    })}
                  </>
                )}
                {BackLogoSrc && (
                  <>
                    {BackProps.map((image, index) => (
                      // Using parentheses for an implicit return
                      <SkewedImage key={index} {...image} />
                    ))}
                  </>
                )}
                {SideLogoSrc && (
                  <>
                    {SideProps.map((image, index) => (
                      <SkewedImage key={index} {...image} />
                    ))}
                  </>
                )}
              </Layer>
              <Layer>
                {rectangles.map((rect, i) => {
                  return (
                    <Rectangle
                      key={i}
                      shapeProps={rect}
                      isSelected={rect.id === selectedId}
                      onSelect={() => {
                        selectShape(rect.id);
                      }}
                      onChange={(newAttrs) => {
                        const rects = rectangles.slice();
                        rects[i] = newAttrs;
                        setRectangles(rects);
                      }}
                      onDelete={() => handleDelete(rect.id)}
                    />
                  );
                })}
              </Layer>
            </Stage>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Customizer;

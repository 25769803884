import './App.css';
import HtRoutes from './HtRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/shared/Header';
import { useLocation } from 'react-router-dom';
function App() {
  const location = useLocation();
  return (
    <>
    {location.pathname !== '/login' && <Header />}
     <HtRoutes/>
    </>
  );
}

export default App;

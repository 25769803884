import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const navigate =useNavigate();
    // State variables for input values
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // State variables for validation states
    const [validated, setValidated] = useState(false);

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (!form.checkValidity()) {
            event.stopPropagation();
        } else {
            // Users credentials
            const users = {
                "publisher@gmail.com": "ht-456",
                "publisher2@gmail.com": "ht-234",
                "publisher3@gmail.com": "ht-345",
                "publisher4@gmail.com": "ht-456",
                "publisher5@gmail.com": "ht-555",
                "publisher6@gmail.com": "ht-666",
                // Add more users here
            };

            // Authentication check
            if (users[email] && users[email] === password) {
                localStorage.setItem("verify", true);
                navigate("/");
            } else {
                toast.error('Incorrect credentials!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }

        setValidated(true);
    };

    return (
        
        <section className="pt-5 pb-5 mt-0 align-items-center d-flex bg-dark login-section bg-overlay login-bg">
             <ToastContainer />
            <Container fluid>
                <Row className="justify-content-end align-items-center d-flex-row text-center login-section">
                    <Col xs={12} md={12} lg={4}>
                        <Card className="login-card rounded-5 shadow-lg">
                            <Row className="justify-content-between align-items-center px-3 py-4">
                                <Col lg={12}>
                                    <h3>HT-PUBLISHING</h3>
                                </Col>
                            </Row>
                            <Card.Body className="login-card-body px-5">
                                <p className="text-start login-heading pb-2 fs-3">Login to Customize</p>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className=" forms-inputs pt-4 text-start" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3 forms-inputs pt-4 text-start" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control required type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a password.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="my-4 w-100 back-btn">
                                        Sign In
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Login;


















// import React, { useState } from 'react';
// import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
// // Import Bootstrap CSS in the entry file of your React app to make Bootstrap styles available across the project
// // You might already have this line in your index.js or App.js file

// const Login = () => {
//     return (
//         <section className="pt-5 pb-5 mt-0 align-items-center d-flex bg-dark login-section bg-overlay login-bg" >
//         <Container fluid >
//             <Row className="justify-content-end align-items-center d-flex-row text-center  login-section">
//                 <Col xs={12} md={12} lg={4}>
//                     <Card className="login-card rounded-5 shadow-lg">
//                         <Row className="justify-content-between align-items-center px-3 py-4">
//                             <Col lg={12}>
//                                <h3>HT-PUBLISHING</h3>
//                             </Col>
//                         </Row>
//                         <Card.Body className="login-card-body px-5">
//                             <p className="text-start login-heading pb-2 fs-3">Login to HT </p>
//                             <Form action="">
//                                 <div className="forms-inputs pt-4 text-start">
//                                 <Form.Label>Email address</Form.Label>
//                                     <Form.Control className="name-input w-100 sign-input email-input" autoComplete="off" type="email" placeholder="example@email.com" />
//                                 </div>
//                                 <Form.Group className="forms-inputs pt-4 text-start">
//                                 <Form.Label>Password</Form.Label>
//                                     <Form.Control type="password" className="form-control name-input w-100 sign-input" placeholder="Password" />
//                                 </Form.Group>
//                             </Form>
//                             <Row className="justify-content-between align-items-center px-3 py-4">
//                                 <Col lg={6} className="new-user p-0 text-start">
                                   
//                                 </Col>
//                                 <Col lg={12} className="text-end">
//                                     <Button variant="primary" type="submit" className="mt-4 sign text-white w-100 ">Sign In</Button>
//                                 </Col>
//                             </Row>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     </section>

//       );
// }

// export default Login